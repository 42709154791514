import Api from "@/services/Index";
import i18n from "@/plugins/i18n";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.get('/registrations', config);
}

const yoksisImport = async (academicYear, registrationType) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    let uri = "/registrations/import/yoksis/"+academicYear+"/"+registrationType;
    return Api.get(uri);
}

const yoksisExport = async (academicYear, registrationType) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    let uri = "/registrations/export/yoksis/"+academicYear+"/"+registrationType;
    return Api.get(uri,{responseType: 'arraybuffer'});
}

const yoksisPhotoImport = async (academicYear, registrationType) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    let uri = "/registrations/import/yoksis/photos/"+academicYear+"/"+registrationType;
    return Api.get(uri);
}

const yoksisPhotoExport = async (academicYear, registrationType) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;;
    let uri = "/registrations/export/yoksis/photos/"+academicYear+"/"+registrationType;
    return Api.get(uri,{responseType: 'arraybuffer'});
}

const getDocumentTypes = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/registration/document/types', config);
}

const showDocumentTypes = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/registration/document/types/'+id);
}

const storeDocumentType = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if(typeof data.id!='undefined' && data.id>0){
        return Api.put('/registration/document/types/'+data.id, data);
    }
    return Api.post('/registration/document/types', data);
}

const deleteDocumentType = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/registration/document/types/'+id);
}

const getProficiency = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.get('/registrations/proficiency', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.get('/registrations/'+id);
}

const putProficiency = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.put('/registrations/proficiency/'+id, data);
}

const downloadFile = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/registrations/file/'+uuid,{responseType: 'arraybuffer'});
}

const register = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.put('/registrations/'+id+'/register', data);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    Api.defaults.headers.common['Accept-Language'] = i18n.locale;
    return Api.post('/registrations', formData);
}

const getDocuments = async (registrationId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/registrations/'+registrationId+'/documents');
}

const uploadDocuments = async (registrationId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/registrations/'+registrationId+'/documents', formData);
}

const downloadDocuments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/registrations/documents/download', formData,{responseType: 'arraybuffer'});
}

const deleteDocuments = async (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/registrations/'+uuid+'/documents');
}

const show = async (registrationId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/registrations/'+registrationId);
}

const update = async (registrationId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/registrations/'+registrationId, formData);
}

const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/registrations/excel-export', config);
}

const deleteRegister = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/registrations/'+id);
}
const importExcel = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/registrations/import', formData);
}

export default {
    getAll,
    importExcel,
    yoksisImport ,
    yoksisExport,
    yoksisPhotoExport,
    yoksisPhotoImport,
    getDocumentTypes,
    showDocumentTypes,
    storeDocumentType,
    deleteDocumentType,
    getProficiency,
    get,
    putProficiency,
    downloadFile,
    store,
    getDocuments,
    uploadDocuments,
    downloadDocuments,
    deleteDocuments,
    show,
    update,
    register,
    exportExcel,
    deleteRegister}
